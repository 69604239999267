
import { defineComponent } from "vue";
import PriceChanged from "@/components/flight_booking/price_change_popup.vue";
import Loader from "@/components/general/loader.vue";
import TheFooter from "@/components/TheFooter.vue";
import Payment from "@/components/general/payment_popup.vue";
import TheHeader from "@/components/TheHeader.vue";
import Navigator from "@/components/flight/navigator.vue";
import FlightDetails from "@/components/flight_payment/flight_detail.vue";
import FlightExtraInfo from "@/components/flight_payment/extra_info.vue";
import ContactInfo from "@/components/flight_booking/contact_info.vue";
import BookingSteps from "@/components/flight_booking/steps.vue";
import Passengers from "@/components/flight_booking/passenger_list.vue";
import PaymentTypes from "@/components/flight_booking/payment_type.vue";
import { Passenger } from "@/models/order_modes";
import {
  ResponseStatusType,
  StatusEnum,
} from "@/constants/response_status_type";
import { User } from "@/models/auth/user_models";
import { LocalDb } from "@/repository/localdb";
import { SearchParameter } from "@/models/search_parameters";
import { AvioneMessage } from "@/constants/error_messages";
import { OrderData } from "@/models/stored_data_models";
import { PaymentType } from "@/models/collection_models";
import Swal from "sweetalert2";
import { PaymentService } from "@/services/payment_service";
import {
  FlightPaymentData,
  FlightPaymentDataRequest,
} from "@/models/payment_models";

export default defineComponent({
  name: "flight_booking_payment",
  components: {
    TheFooter,
    TheHeader,
    Navigator,
    FlightDetails,
    BookingSteps,
    FlightExtraInfo,
    Loader,
    ContactInfo,
    Passengers,
    PaymentTypes,
    PriceChanged,
    Payment,
  },
  data() {
    return {
      validate: false,
      loading: false,
      loadingMessage: "",
      isLoginOpen: false,
      priceChangeShow: false,
      openPaymentPopup: false,
      paymentUrl: "",
      currentUser: {} as User | null,
      passengers: [] as Passenger[],
      paymentData: {} as FlightPaymentData,
      paymentType: null,
      // ticketBooking: {} as TicketBooking,
      orderData: {} as OrderData | undefined,
      searchParameter: {} as SearchParameter | null,
      amountFromUserBalance: 0,
    };
  },

  created() {
    this.currentUser = LocalDb.getCurrentUser();
    this.searchParameter = LocalDb.getSearchParameters();

    const pnrNumber = this.$route.params.id;
    if (pnrNumber) {
      const order: OrderData | undefined = LocalDb.getMyFlightOrder(
        pnrNumber.toString()
      );
      if (order) {
        this.orderData = order;
        this.passengers = order.passengers;
      } else {
        window.location.href = "/";
      }
    }
  },
  methods: {
    onPaymentFormCloseEventHandler: function() {
      this.openPaymentPopup = false;
      this.paymentUrl = "";
      // if (this.orderData)
      //   window.location.href = "/flight/payment/confirm/" + this.orderData.orderId;
    },
    onContinuePriceChangeClickEventHandler: async function() {
      this.priceChangeShow = false;
      await this.onPaymentClickEventHandler();
    },
    onClosePriceChangeClickEvent: function() {
      this.priceChangeShow = false;
      this.$router.push({
        path: "/profile/orders",
        params: {
          lang: this.$route.params.lang,
        },
      });
    },
    onPaymentTypeChangeEventHandler: function(value: any) {
      this.paymentType = value.selectedPaymentType;
      this.amountFromUserBalance = value.amountFromUserBalance;
    },

    onPaymentClickEventHandler: async function() {
      if (!this.paymentType) {
        await Swal.fire(
          "",
          this.$t(AvioneMessage.PAYMENT_TYPE_CHOOSE),
          "error"
        );
        return;
      }
      if (this.paymentType === "notEnoughInBalance") {
        await Swal.fire("", this.$t(AvioneMessage.NotEnoughInBalance), "error");
        return;
      }
      if (this.orderData == undefined) {
        await Swal.fire("", this.$t(AvioneMessage.ORDER_NOT_FOUND), "error");
        return;
      }
      this.loading = true;
      this.loadingMessage = this.$t(
        AvioneMessage.PAYMENT_TYPE_AVIPAY_REDIRECTION
      );

      const paymentData = {
        orderId: this.orderData.orderId,
        buyId: this.orderData.buyId,
        amountFromUserBalance: this.amountFromUserBalance,
        requiredOnlinePayment:
          parseInt(this.orderData?.amount) - this.amountFromUserBalance,
        paymentMethod: this.paymentType,
      };

      if (this.paymentType === "Balance") {
        await PaymentService.getPaymentFromBalance({
          orderId: this.orderData?.orderId,
        }).then((response) => {
          this.loading = false;
          this.loadingMessage = "";
          if (response.status == ResponseStatusType.Error) {
            if (response.data.success_url) {
              window.location.href = response.data.success_url;
            } else if (response.data.status == 3) {
              this.priceChangeShow = true;
              this.paymentData = response.data;
              if (this.orderData) {
                this.orderData.amount = response.data.newPrice;
              }
            } else {
              Swal.fire("Body error", response.data.message, "error");
            }
          } else {
            Swal.fire("Network error", response.message, "error");
          }
        });
      }

      await PaymentService.getPaymentData(paymentData).then((response) => {
        this.loading = false;
        this.loadingMessage = "";
        if (response.status == ResponseStatusType.Error) {
          if (response.data.success_url) {
            window.location.href = response.data.success_url;
          } else if (response.data.status == 3) {
            this.priceChangeShow = true;
            this.paymentData = response.data;
            if (this.orderData) {
              this.orderData.amount = response.data.newPrice;
            }
          } else {
            Swal.fire("Body error", response.data.message, "error");
          }
        } else {
          Swal.fire("Network error", response.message, "error");
        }
      });
    },
    onCancelPaymentRequest: function() {
      LocalDb.removeBookingTicket();
      this.redirectToHome();
    },
    redirectToHome: function() {
      window.location.href = "/";
    },
  },
});
